import request from '@/router/axios';
//const urlPrefix = process.env.VUE_APP_LOCAL_DEVELOPMENT == 'true' ? '' : '/sys'
const urlPrefix = '/sys'

export function getListpage(obj) {
  return request({
    url: urlPrefix + '/demoperson/listpage',
    method: 'POST',
    data: obj,
  });
}

/**
 *  获取动态列表数据
 */
export function getdynamicList(obj) {
  return request({
    url: urlPrefix + '/demoperson/listEmpInfo',
    method: 'Post',
    data: obj,
  });
}


/**
 *  获取动态表单回显值
 */
export function getDynamicFormValue(empId) {
  return request({
    url: urlPrefix +'demoperson/getEmpInfo/'+empId,
    method: 'GET',
  });
}



/**
 *  根据id修改数据
 * @param obj
 */
export function updateListpage(obj) {
  return request({

    url: urlPrefix + '/demoperson',
    method: 'put',
    data: obj,
  });
}

export function addListpage(obj) {
  return request({

    url: urlPrefix + '/demoperson',
    method: 'post',
    data: obj,
  });
}
//删除数据
export function deleteData(obj) {
  return request({

    url: urlPrefix + '/demoperson/del/'+obj,
    method: 'delete',
    data: obj,
  });
}

// 下载模板
export function downloadExcleTmpl(obj) {
  return request({
    url: urlPrefix +'/excel/downloadtmpexcle',
   // method: 'get',
    responseType: 'arraybuffer',
    params: obj,
  });
}

// 下载excle上传的错误信息
export function downloadErrorExcle(obj) {
  return request({
    url: urlPrefix +'/excel/downloadError',
    // method: 'get',
    responseType: 'arraybuffer',
    params: obj,
  });
}

// 下载城市信息文件
export function donwloadCityFile(obj) {
  return request({
    url: urlPrefix +'/syscodeitem/city',
    // method: 'get',
    responseType: 'arraybuffer',
    params: obj,
  });
}
export function exportTable(obj) {
  return request({
    url:urlPrefix + '/excel/exportExcleAll',
    method: 'post',
    data: obj,
    responseType: 'arraybuffer',
  });
}

export function exportTableForHeaders(obj) {
  return request({
    url: urlPrefix +'/excel/exportHeaders',
    method: 'POST',
    data: obj,
    responseType: 'arraybuffer',
  });
}
