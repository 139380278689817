<template>
  <div>

    <el-form :inline="true" :model="listQuery" class="editform">

      <template>

        <el-tabs v-model="listQuery.activeName" @tab-click="handleClick">
          <el-tab-pane label="雇员明细" name="1002">
          <div class="divButtonRigth">
            <el-button type="primary" size="small" fixed="right" @click="exportDetail">导出明细</el-button>
            <el-button type="primary" size="small" fixed="right" @click="back">返回</el-button>
          </div>
          <bsp-table
            v-if="refresh"
            ref="publicTable"
            :data.sync="list"
            :tree-config="true"
            :ciic-data-filter.sync="listQuery"
            @ciic-data-refresh="searchDetail"
            show-header-overflow show-overflowwidth show-overflow
            v-loading="loading"
            element-loading-text="拼命加载中"
            element-loading-spinner="el-icon-loading"
            show-footer-overflow
            show-footer
            :footer-method="footerMethod"
            height="500"
          >
            <vxe-table-column type="selection" width="60" fixed="left"/>
            <vxe-table-column title="序号" type="index" width="60" fixed="left" align="center"></vxe-table-column>

            <div v-if="listQuery.activeName === '1002' && this.show.show1002">
              <bsp-table-column title="客户名称" field="cusName" filter-type="input" width="100">
                <template slot-scope="scope"><span>{{scope.row.e204399}}</span></template>
              </bsp-table-column>
              <bsp-table-column title="雇员姓名" field="empName" filter-type="input" width="100">
                <template slot-scope="scope"><span>{{scope.row.e204344}}</span></template>
              </bsp-table-column>
              <bsp-table-column title="报税国籍" field="national" filter-type="input" width="100">
                <template slot-scope="scope"><span>{{scope.row.e204346 | dictFilter(dicts)}}</span></template>
              </bsp-table-column>
              <bsp-table-column title="雇员证件号" field="identityNum" filter-type="input" width="100">
                <template slot-scope="scope"><span>{{scope.row.e204347}}</span></template>
              </bsp-table-column>
              <bsp-table-column title="计税类型" field="taxType" filter-type="input" width="100">
                <template slot-scope="scope"><span>{{scope.row.e204208 | dictFilter(dicts)}}</span></template>
              </bsp-table-column>
              <bsp-table-column title="报税名义" field="taxNominal" filter-type="input" width="100">
                <template slot-scope="scope"><span>{{scope.row.e204210 | dictFilter(dicts)}}</span></template>
              </bsp-table-column>
              <bsp-table-column title="税金缴纳月份" field="taxDeclareMonth" filter-type="input" width="100">
                <template slot-scope="scope"><span>{{scope.row.e204398}}</span></template>
              </bsp-table-column>
<!--              <bsp-table-column title="收款来源" field="e204355" filter-type="input" width="100">-->
<!--                <template slot-scope="scope"><span>{{scope.row.e204355}}</span></template>-->
<!--              </bsp-table-column>-->
              <bsp-table-column title="银行类别" field="bankType" filter-type="input" width="100">
                <template slot-scope="scope"><span>{{scope.row.e204265 | dictFilter(dicts)}}</span></template>
              </bsp-table-column>
              <bsp-table-column title="开户名" field="accountName" filter-type="input" width="100">
                <template slot-scope="scope"><span>{{scope.row.e204266}}</span></template>
              </bsp-table-column>
              <bsp-table-column title="开户账号" field="accountNum" filter-type="input" width="100">
                <template slot-scope="scope"><span>{{scope.row.e204267}}</span></template>
              </bsp-table-column>
              <bsp-table-column title="开户银行全称" field="bankFullName" filter-type="input" width="100">
                <template slot-scope="scope"><span>{{scope.row.e204268}}</span></template>
              </bsp-table-column>
              <bsp-table-column title="账号地区" field="bankAccountArea" filter-type="input" width="100">
                <template slot-scope="scope"><span>{{scope.row.e204269}}</span></template>
              </bsp-table-column>
              <bsp-table-column title="工行地区代码" field="ibicAreNum" filter-type="input" width="100">
                <template slot-scope="scope"><span>{{scope.row.e204270}}</span></template>
              </bsp-table-column>
              <bsp-table-column title="是否残疾人" field="isDisabled" filter-type="input" width="100">
                <template slot-scope="scope"><span>{{scope.row.e204356 | dictFilter(dicts)}}</span></template>
              </bsp-table-column>
              <bsp-table-column title="是否税金托收" field="isTaxCollection" filter-type="input" width="100">
                <template slot-scope="scope"><span>{{scope.row.e204359 | dictFilter(dicts)}}</span></template>
              </bsp-table-column>
              <bsp-table-column title="委托服务机构" field="e204214" filter-type="input" width="100">
                <template slot-scope="scope"><span>{{scope.row.e204214}}</span></template>
              </bsp-table-column>
              <bsp-table-column title="收款行开户名" field="skhkhm" filter-type="input" width="100">
                <template slot-scope="scope"><span>{{scope.row.skhkhm}}</span></template>
              </bsp-table-column>
              <bsp-table-column title="收款行开户行" field="skhkhh" filter-type="input" width="100">
                <template slot-scope="scope"><span>{{scope.row.skhkhh}}</span></template>
              </bsp-table-column>
              <bsp-table-column title="收款行账号" field="skhzh" filter-type="input" width="100">
                <template slot-scope="scope"><span>{{scope.row.skhzh}}</span></template>
              </bsp-table-column>
              <bsp-table-column title="薪资计税区域" field="taxArea" filter-type="input" width="100">
                <template slot-scope="scope"><span>{{scope.row.e204215 | dictFilter(dicts)}}</span></template>
              </bsp-table-column>
              <bsp-table-column title="工资劳务费年份" field="e204212" filter-type="input" width="100">
                <template slot-scope="scope"><span>{{scope.row.e204212}}</span></template>
              </bsp-table-column>
              <bsp-table-column title="工资劳务费月份" field="e204213" filter-type="input" width="100">
                <template slot-scope="scope"><span>{{scope.row.e204213}}</span></template>
              </bsp-table-column>
              <bsp-table-column title="成本工资" field="e204243" filter-type="input" width="100">
                <template slot-scope="scope"><span>{{scope.row.e204243 | moneyFilterStr}}</span></template>
              </bsp-table-column>
              <bsp-table-column title="工资往来" field="e204244" filter-type="input" width="100">
                <template slot-scope="scope"><span>{{scope.row.e204244 | moneyFilterStr}}</span></template>
              </bsp-table-column>
              <bsp-table-column title="税前养老个人免税部分" field="e204216" filter-type="input" width="100">
                <template slot-scope="scope"><span>{{scope.row.e204216 | moneyFilterStr}}</span></template>
              </bsp-table-column>
              <bsp-table-column title="税前失业个人免税部分" field="e204217" filter-type="input" width="100">
                <template slot-scope="scope"><span>{{scope.row.e204217 | moneyFilterStr}}</span></template>
              </bsp-table-column>
              <bsp-table-column title="税前医疗个人免税部分" field="e204218" filter-type="input" width="100">
                <template slot-scope="scope"><span>{{scope.row.e204218 | moneyFilterStr}}</span></template>
              </bsp-table-column>
              <bsp-table-column title="税前公积金个人免税部分" field="e204219" filter-type="input" width="100">
                <template slot-scope="scope"><span>{{scope.row.e204219 | moneyFilterStr}}</span></template>
              </bsp-table-column>
              <bsp-table-column title="公积金单位计税部分" field="e204483" filter-type="input" width="100">
                <template slot-scope="scope"><span>{{scope.row.e204483 | moneyFilterStr}}</span></template>
              </bsp-table-column>
              <bsp-table-column title="养老个人扣款月次" field="e204220" filter-type="input" width="100">
                <template slot-scope="scope"><span>{{scope.row.e204220}}</span></template>
              </bsp-table-column>
              <bsp-table-column title="失业个人扣款月次" field="e204221" filter-type="input" width="100">
                <template slot-scope="scope"><span>{{scope.row.e204221}}</span></template>
              </bsp-table-column>
              <bsp-table-column title="医疗个人扣款月次" field="e204222" filter-type="input" width="100">
                <template slot-scope="scope"><span>{{scope.row.e204222}}</span></template>
              </bsp-table-column>
              <bsp-table-column title="公积金个人扣款月次" field="e204223" filter-type="input" width="100">
                <template slot-scope="scope"><span>{{scope.row.e204223}}</span></template>
              </bsp-table-column>
              <bsp-table-column title="应纳税所得额调增项" field="e204224" filter-type="input" width="100">
                <template slot-scope="scope"><span>{{scope.row.e204224 | moneyFilterStr}}</span></template>
              </bsp-table-column>
              <bsp-table-column title="税前商报计税部分" field="e204225" filter-type="input" width="100">
                <template slot-scope="scope"><span>{{scope.row.e204225 | moneyFilterStr}}</span></template>
              </bsp-table-column>
              <bsp-table-column title="税前其他计税部分" field="e204226" filter-type="input" width="100">
                <template slot-scope="scope"><span>{{scope.row.e204226 | moneyFilterStr}}</span></template>
              </bsp-table-column>
              <bsp-table-column title="应纳税所得额调减项" field="e204227" filter-type="input" width="100">
                <template slot-scope="scope"><span>{{scope.row.e204227 | moneyFilterStr}}</span></template>
              </bsp-table-column>
              <bsp-table-column title="税前捐赠免税部分" field="e204228" filter-type="input" width="100">
                <template slot-scope="scope"><span>{{scope.row.e204228 | moneyFilterStr}}</span></template>
              </bsp-table-column>
              <bsp-table-column title="税前其他免税部分" field="e204229" filter-type="input" width="100">
                <template slot-scope="scope"><span>{{scope.row.e204229 | moneyFilterStr}}</span></template>
              </bsp-table-column>
              <bsp-table-column title="税后五险一金减项" field="e204230" filter-type="input" width="100">
                <template slot-scope="scope"><span>{{scope.row.e204230 | moneyFilterStr}}</span></template>
              </bsp-table-column>
              <bsp-table-column title="税后扣除养老个人计税部分" field="e204231" filter-type="input" width="100">
                <template slot-scope="scope"><span>{{scope.row.e204231 | moneyFilterStr}}</span></template>
              </bsp-table-column>
              <bsp-table-column title="税后扣除失业个人计税部分" field="e204232" filter-type="input" width="100">
                <template slot-scope="scope"><span>{{scope.row.e204232 | moneyFilterStr}}</span></template>
              </bsp-table-column>
              <bsp-table-column title="税后扣除医疗个人计税部分" field="e204233" filter-type="input" width="100">
                <template slot-scope="scope"><span>{{scope.row.e204233 | moneyFilterStr}}</span></template>
              </bsp-table-column>
              <bsp-table-column title="税后扣除公积金个人计税部分" field="e204234" filter-type="input" width="100">
                <template slot-scope="scope"><span>{{scope.row.e204234 | moneyFilterStr}}</span></template>
              </bsp-table-column>
              <bsp-table-column title="税后减项" field="e204235" filter-type="input" width="100">
                <template slot-scope="scope"><span>{{scope.row.e204235 | moneyFilterStr}}</span></template>
              </bsp-table-column>
              <bsp-table-column title="税后应客户要求扣款（工资）" field="e204236" filter-type="input" width="100">
                <template slot-scope="scope"><span>{{scope.row.e204236 | moneyFilterStr}}</span></template>
              </bsp-table-column>
              <bsp-table-column title="税后扣除开户费" field="e204237" filter-type="input" width="100">
                <template slot-scope="scope"><span>{{scope.row.e204237 | moneyFilterStr}}</span></template>
              </bsp-table-column>
              <bsp-table-column title="税后加项" field="e204238" filter-type="input" width="100">
                <template slot-scope="scope"><span>{{scope.row.e204238 | moneyFilterStr}}</span></template>
              </bsp-table-column>
              <bsp-table-column title="税后增加独生子女补贴" field="e204239" filter-type="input" width="100">
                <template slot-scope="scope"><span>{{scope.row.e204239 | moneyFilterStr}}</span></template>
              </bsp-table-column>
              <bsp-table-column title="税后增加煤火费补贴" field="e204240" filter-type="input" width="100">
                <template slot-scope="scope"><span>{{scope.row.e204240 | moneyFilterStr}}</span></template>
              </bsp-table-column>
              <bsp-table-column title="税后增加其他免税补贴" field="e204241" filter-type="input" width="100">
                <template slot-scope="scope"><span>{{scope.row.e204241 | moneyFilterStr}}</span></template>
              </bsp-table-column>
              <bsp-table-column title="应发金额" field="e204256" filter-type="input" width="100">
                <template slot-scope="scope"><span>{{scope.row.e204256 | moneyFilterStr}}</span></template>
              </bsp-table-column>
              <bsp-table-column title="应税金额" field="e204261" filter-type="input" width="100">
                <template slot-scope="scope"><span>{{scope.row.e204261 | moneyFilterStr}}</span></template>
              </bsp-table-column>
              <bsp-table-column title="税率" field="e204263" filter-type="input" width="100">
                <template slot-scope="scope"><span>{{scope.row.e204263}}</span></template>
              </bsp-table-column>
              <bsp-table-column title="工资速算扣除数" field="e204264" filter-type="input" width="100">
                <template slot-scope="scope"><span>{{scope.row.e204264 | moneyFilterStr}}</span></template>
              </bsp-table-column>
              <bsp-table-column title="税金" field="e204262" filter-type="input" width="100">
                <template slot-scope="scope"><span>{{scope.row.e204262 | moneyFilterStr}}</span></template>
              </bsp-table-column>
              <bsp-table-column title="以往已交税金合计" field="e204363" filter-type="input" width="100">
                <template slot-scope="scope"><span>{{scope.row.e204363 | moneyFilterStr}}</span></template>
              </bsp-table-column>
              <bsp-table-column title="实发金额" field="e204258" filter-type="input" width="100">
                <template slot-scope="scope"><span>{{scope.row.e204258 | moneyFilterStr}}</span></template>
              </bsp-table-column>
              <bsp-table-column title="成本年终奖" field="e204247" filter-type="input" width="100">
                <template slot-scope="scope"><span>{{scope.row.e204247 | moneyFilterStr}}</span></template>
              </bsp-table-column>
              <bsp-table-column title="年终奖往来" field="e204248" filter-type="input" width="100">
                <template slot-scope="scope"><span>{{scope.row.e204248 | moneyFilterStr}}</span></template>
              </bsp-table-column>
              <bsp-table-column title="年终奖年度" field="" filter-type="input" width="100">
                <template slot-scope="scope"><span>{{scope.row.e204245}}</span></template>
              </bsp-table-column>
              <bsp-table-column title="年终奖应发金额" field="e204246" filter-type="input" width="100">
                <template slot-scope="scope"><span>{{scope.row.e204246 | moneyFilterStr}}</span></template>
              </bsp-table-column>
              <bsp-table-column title="年终奖税率" field="e204252" filter-type="input" width="100">
                <template slot-scope="scope"><span>{{scope.row.e204252}}</span></template>
              </bsp-table-column>
              <bsp-table-column title="年终奖税金" field="e204253" filter-type="input" width="100">
                <template slot-scope="scope"><span>{{scope.row.e204253 | moneyFilterStr}}</span></template>
              </bsp-table-column>
              <bsp-table-column title="年终奖速算扣除数" field="e204249" filter-type="input" width="100">
                <template slot-scope="scope"><span>{{scope.row.e204249 | moneyFilterStr}}</span></template>
              </bsp-table-column>
              <bsp-table-column title="年终奖实发金额" field="e204254" filter-type="input" width="100">
                <template slot-scope="scope"><span>{{scope.row.e204254 | moneyFilterStr}}</span></template>
              </bsp-table-column>
              <bsp-table-column title="年终奖税前免税金额" field="e204250" filter-type="input" width="100">
                <template slot-scope="scope"><span>{{scope.row.e204250 | moneyFilterStr}}</span></template>
              </bsp-table-column>
              <bsp-table-column title="年终奖应税金额" field="e204251" filter-type="input" width="100">
                <template slot-scope="scope"><span>{{scope.row.e204251 | moneyFilterStr}}</span></template>
              </bsp-table-column>
              <bsp-table-column title="年终奖税前其他免税补贴" field="e204255" filter-type="input" width="100">
                <template slot-scope="scope"><span>{{scope.row.e204255 | moneyFilterStr}}</span></template>
              </bsp-table-column>
              <bsp-table-column title="年终奖税后应客户要求扣款" field="e204386" filter-type="input" width="100">
                <template slot-scope="scope"><span>{{scope.row.e204386 | moneyFilterStr}}</span></template>
              </bsp-table-column>
              <bsp-table-column title="代报销金额" field="e204387" filter-type="input" width="100">
                <template slot-scope="scope"><span>{{scope.row.e204387 | moneyFilterStr}}</span></template>
              </bsp-table-column>
              <bsp-table-column title="离职年限" field="e204211" filter-type="input" width="100">
                <template slot-scope="scope"><span>{{scope.row.e204211}}</span></template>
              </bsp-table-column>
              <bsp-table-column title="财务是否退回" field="e204353" filter-type="input" width="100">
                <template slot-scope="scope"><span>{{scope.row.e204353}}</span></template>
              </bsp-table-column>
              <bsp-table-column title="财务退回原因" field="e204354" filter-type="input" width="100">
                <template slot-scope="scope"><span>{{scope.row.e204354}}</span></template>
              </bsp-table-column>

              <bsp-table-column title="备注" field="e204260" filter-type="input" width="100">
                <template slot-scope="scope"><span>{{scope.row.e204260}}</span></template>
              </bsp-table-column>
              <bsp-table-column title="姓名和开户名是否一致" field="e204420" filter-type="input" width="100">
                <template slot-scope="scope"><span>{{scope.row.e204420}}</span></template>
              </bsp-table-column>
              <bsp-table-column title="邮件地址" field="e204349" filter-type="input" width="100">
                <template slot-scope="scope"><span>{{scope.row.e204349}}</span></template>
              </bsp-table-column>
              <bsp-table-column title="使用对公余额" field="e204488" filter-type="input" width="100">
                <template slot-scope="scope"><span>{{scope.row.e204488 | moneyFilterStr}}</span></template>
              </bsp-table-column>
              <bsp-table-column title="委托费成本（合计）" field="e204401" filter-type="input" width="100">
                <template slot-scope="scope"><span>{{scope.row.e204401 | moneyFilterStr}}</span></template>
              </bsp-table-column>
              <bsp-table-column title="累计子女教育专项附加扣除额" field="e204602" filter-type="input" width="100">
                <template slot-scope="scope"><span>{{scope.row.e204602 | moneyFilterStr}}</span></template>
              </bsp-table-column>
              <bsp-table-column title="累计继续教育专项附加扣除额" field="e204603" filter-type="input" width="100">
                <template slot-scope="scope"><span>{{scope.row.e204603 | moneyFilterStr}}</span></template>
              </bsp-table-column>
              <bsp-table-column title="累计住房贷款利息专项附加扣除额" field="e204605" filter-type="input" width="100">
                <template slot-scope="scope"><span>{{scope.row.e204605 | moneyFilterStr}}</span></template>
              </bsp-table-column>
              <bsp-table-column title="累计住房租金专项附加扣除额" field="e204606" filter-type="input" width="100">
                <template slot-scope="scope"><span>{{scope.row.e204606 | moneyFilterStr}}</span></template>
              </bsp-table-column>
              <bsp-table-column title="累计赡养老人专项附加扣除额" field="e204607" filter-type="input" width="100">
                <template slot-scope="scope"><span>{{scope.row.e204607 | moneyFilterStr}}</span></template>
              </bsp-table-column>
              <bsp-table-column title="累计婴幼儿照护专项附加扣除额" field="e204653" filter-type="input" width="100">
                <template slot-scope="scope"><span>{{scope.row.e204653 | moneyFilterStr}}</span></template>
              </bsp-table-column>
              <bsp-table-column title="个人养老金" field="e204660" filter-type="input" width="100">
                <template slot-scope="scope"><span>{{scope.row.e204660 | moneyFilterStr}}</span></template>
              </bsp-table-column>
              <bsp-table-column title="税金所属月份" field="e204492" filter-type="input" width="100">
                <template slot-scope="scope"><span>{{scope.row.e204492}}</span></template>
              </bsp-table-column>
              <bsp-table-column title="rpa处理标志" field="e204616" filter-type="input" width="100">
                <template slot-scope="scope"><span>{{scope.row.e204616}}</span></template>
              </bsp-table-column>
              <bsp-table-column title="支出单编号" field="e204201" filter-type="input" width="100">
                <template slot-scope="scope"><span>{{scope.row.e204201}}</span></template>
              </bsp-table-column>
            </div>

          </bsp-table>
          </el-tab-pane>

        </el-tabs>

      </template>
    </el-form>



  </div>
</template>
<script>

  import BspTable from "@/components/bsp/bsp-table/bsp-table";
  import BspTableColumn from "@/components/bsp/bsp-table-column/bsp-table-column";
  import {
    searchTabs,
    searchPaymentDetailInfo,
    exportDetail
  } from '@/api/payment/payment';
  import {mapGetters} from 'vuex';

  export default {
    name: 'bankDirectDetail',
    inject: ['reload'],
    components: {
      BspTable,
      BspTableColumn,
    },
    computed: {
      ...mapGetters([
        'dicts'
      ]),
    },
    props: {
      currentTab: {
        type: String,
        default: ''
      },

    },
    created() {
      this.searchTabs();
    },
    data() {
      return {
        // 下拉选中的银行类别
        bankTypes: [],
        // 根据银行类别选取的银行信息
        bankInfos: [],
        // 用于接收后台返回的分页数据
        list: [],
        //分页查询对象
        listQuery: {
          limit: 50,
          page: 1,
          total: 0,
          paymentNo: '',
          paymentStatus: '',
          accountingMonth: '',
          activeName: '',
          paymentType: '0',
          paymentId: this.$route.query.paymentId,
          payWageClass: this.$route.query.payWageClass,
          createType: this.$route.query.createType
        },
        llQuery: this.$route.query.listQuery,
        loading: false,
        bo: {},
        tabs: [],
        show: {
          show1002: false,

        },
        pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
        startAndendDate: '',
        refresh:true
      }
    },
    methods: {

      back: function() {
        this.$router.push({path: '/consignee/payment_confirm', query: {listQuery: this.llQuery}})
      },

      searchTabs: function() {
        searchTabs(this.listQuery).then(res => {
          debugger
          this.tabs = res.data;
          this.listQuery.activeName = this.tabs[1];
          for (let i in this.show) {
            for (let j in this.tabs) {
              if (i.substring(4) === this.tabs[j]) {
                this.show[i] = true;
                break;
              }
            }
          }
          this.searchDetail();
        })

      },

      handleClick(tab, event) {
        this.$set(this.listQuery,"filters", [])
        for(let key in this.$refs.publicTable.filtersStore){
          delete this.$refs.publicTable.filtersStore[key].value;
        }
        this.$refs.publicTable.$refs.ciicTable.clearFilter();
        this.refresh = false;
        this.searchDetail();

      },
      // 导出明细
      exportDetail: function () {
        exportDetail(this.listQuery).then(res => {
          if (res.data.size === 0) {
            alert("无导出数据");
            return;
          }
          const url = window.URL.createObjectURL(res.data);
          const link = document.createElement('a');
          link.href = url;
          const fileName = `${res.headers['content-disposition'].replace('attachment;filename=','')}`;
          let channelUrl=decodeURIComponent(fileName);
          link.setAttribute('download', channelUrl);
          link.click()
        })
      },


      // 查看支付单明细
      searchDetail: function () {
        this.loading  =true;
        searchPaymentDetailInfo(this.listQuery).then(res => {
          this.bo = res.data.data;
          this.list = [];
          this.list = res.data.records;
          this.listQuery.total = res.data.total;
          this.refresh = true;
          this.loading  =false;
        })
      },
      // 底部合计
          footerMethod({columns, data}) {
            return [
              columns.map((column, columnIndex) => {
                if (columnIndex === 0) {  return '合计' }
                if (['e204243','e204244','e204216','e204217','e204218','e204219','e204483','e204224',"e204225",
                "e204226","e204227","e204228","e204229","e204230","e204231","e204232","e204233","e204234","e204235","e204236","e204237"
                ,"e204238","e204239","e204240","e204241","e204256","e204261","e204264","e204262","e204363","e204258","e204247","e204248"
                ,"e204245","e204246","e204252","e204253","e204249","e204254","e204250","e204251","e204255","e204386","e204387"
                ,"e204488","e204401","e204602","e204603","e204605","e204606","e204607"].includes(column.property)) {
                  return this.$options.filters['moneyFilter'](this.$utils.sum(data, column.property))
                }
                return null
              })
            ]
          },
    }
  }
</script>
<style scoped>

  .editform {
    margin: 0;
  }
</style>
