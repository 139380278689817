import request from '@/router/axios';
const urlPrefix = '';

// 根据条件查询支付单列表
export function searchPaymentsByConditions(obj) {
  return request({
    url: urlPrefix + '/searchPaymentsByConditions',
    method: 'post',
    data: obj
  })
}

export function searchTabs(obj) {
  return request({
    url: urlPrefix + '/searchTabs',
    method: 'post',
    data: obj
  })
}

// 查询支付单明细详情
export function searchPaymentDetailInfo(obj) {
  return request({
    url: urlPrefix + '/searchPaymentDetailInfo',
    method: 'post',
    data: obj
  })
}

// 打印财务公司支付单
export function printFinancialPayment(obj) {
  return request({
    url: urlPrefix + '/printFinancialPayment',
    method: 'post',
    data: obj
  })
}

// 打印退票信息
export function printRefundNotice(obj) {
  return request({
    url: urlPrefix + '/printRefundNotice',
    method: 'post',
    data: obj
  })
}

// 打印雇员明细
export function printEmployeeDetail(obj) {
  return request({
    url: urlPrefix + '/printEmployeeDetail',
    method: 'post',
    data: obj
  })
}

// 导出明细
export function exportDetail(obj) {
  return request({
    url: urlPrefix + "/export",
    method: 'post',
    responseType: 'blob',
    data: obj
  })
}

// 根据条件查询支付单列表
export function searchPaymentsList(obj) {
  return request({
    url: urlPrefix + '/payment/payment_list',
    method: 'post',
    data: obj
  })
}

// 打印对公支付单
export function printPublicPayment(obj) {
  return request({
    url: urlPrefix + '/printPublicPayment',
    method: 'post',
    data: obj
  })
}

// 打印对公支付单明细
export function printPublicPaymentDetail(obj) {
  return request({
    url: urlPrefix + '/printPublicPaymentDetail',
    method: 'post',
    data: obj
  })
}

// 支付确认记录
export function confirmRecord(obj) {
  return request({
    url: urlPrefix + '/payment/confirmRecord',
    method: 'post',
    data: obj
  })
}