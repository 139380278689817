<template>
  <div>
    <div v-loading="loading" class="divButtonRigth">
      <el-button type="primary" size="small" fixed="right"
                 v-if="this.currentTab=='33730001' && this.pageOprator == 'QG-manage'"
                 :disabled="isDisabled" @click="substituteConfirm">代确认
      </el-button>
      <el-button type="primary" size="small" fixed="right"
                 v-if="this.currentTab=='33730001' && this.pageOprator == 'QG-manage'"
                 :disabled="isDisabled" @click="_back">退回
      </el-button>
      <el-button type="primary" size="small" fixed="right"
                 v-if="this.currentTab=='33730002'"
                 :disabled="isDisabled" @click="downLoadFinTaxAgreement">下载
      </el-button>
      <el-button type="primary" size="small" fixed="right"
                 v-if="this.currentTab=='33730002'"
                 :disabled="isDisabled" @click="exportDetails" >导出</el-button>

      <el-button type="primary" size="small" fixed="right"
                 v-if="detailVisible=='detail'"
                 :disabled="isDisabled" @click="goback">返回
      </el-button>
    </div>
    <!-- 退回-->
    <bsp-dialog
      dialog-ref="auditBackPage"
      :dialog-visible.sync="backDialog"
      width="500px"
      :title="backTitle"
      @closed="close"
      :before-close="beforeClose"
      :show-close = "false"
    >
      <div slot="body">
        <audit-back-page ref="auditBackRef" @cancelLoadBtn="cancelLoadBtn" :listForm="listForm"  @back="goback"></audit-back-page>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="stGoBack">确 定</el-button>
        <el-button @click="backDialog = false">取 消</el-button>
      </div>
    </bsp-dialog>


    <!--申请列表-->
    <bsp-table v-if="this.detailVisible=='false'"
               :data.sync="list"
               :tree-config="true"
               :ciic-data-filter.sync="listQuery"
               @ciic-data-refresh="__getFinTaxAgreementList"
               @select-change="selectChange"
               @select-all="selectAll"
               show-header-overflow show-overflowwidth show-overflow
               v-loading="loading"
               element-loading-text="拼命加载中"
               element-loading-spinner="el-icon-loading"
               show-footer-overflow
               show-footer
               :footer-method="footerMethod"
               height="89%"
    >
      <vxe-table-column type="selection" width="60" fixed="left"/>
      <vxe-table-column title="序号" type="index" width="60" fixed="left" align="center"></vxe-table-column>
      <bsp-table-column title="协议编号" field="protocolNumber" filter-type="input" fixed="left" v-if="listQuery.applyType == '33730002'">
        <template slot-scope="scope"><span>{{scope.row.protocolNumber}}</span></template>
      </bsp-table-column>
      <bsp-table-column title="受托方" field="orgName" filter-type="input" fixed="left">
        <template slot-scope="scope"><span>{{scope.row.orgName}}</span></template>
      </bsp-table-column>
      <bsp-table-column title="客户名称" field="cusName" filter-type="input" fixed="left">
        <template slot-scope="scope"><span>{{scope.row.cusName}}</span></template>
      </bsp-table-column>

      <bsp-table-column title="地区" field="region" filter-type="input" fixed="left">
        <template slot-scope="scope"><span>{{scope.row.region}}</span></template>
      </bsp-table-column>

      <bsp-table-column title="业务类型" field="biz_type" fixed="left"
                        filter-type="select"    filter-rule="in"   set-id="3371">
        <template slot-scope="scope"><span>{{scope.row.bizTypeName | dictFilter(dicts) }}</span></template>
      </bsp-table-column>

      <bsp-table-column title="服务业务" field="serviceBusiness" fixed="left"
                        filter-type="select-like"    filter-rule="input"   set-id="3372">
        <template slot-scope="scope"><span>{{scope.row.serviceBusinessName | dictFilter(dicts) }}</span></template>
      </bsp-table-column>

      <bsp-table-column title="服务项目" field="serviceItem" filter-type="input" fixed="left">
        <template slot-scope="scope"><span>{{scope.row.serviceItem}}</span></template>
      </bsp-table-column>

      <bsp-table-column title="协议状态" field="agreement_status" fixed="left"
                        filter-type="select"    filter-rule="in"   set-id="3374">
        <template slot-scope="scope"><span>{{scope.row.statusName | dictFilter(dicts) }}</span></template>
      </bsp-table-column>
      <bsp-table-column title="受托负责人" field="stManager" width="100"
                        filter-type="input" fixed="left">
        <template slot-scope="scope"><span>{{scope.row.stManager}}</span></template>
      </bsp-table-column>
      <bsp-table-column title="客户负责人" field="applicantName" width="100" v-if="pageOprator == 'QG-manage'"
                        filter-type="input" fixed="left">
        <template slot-scope="scope"><span>{{scope.row.applicantName}}</span></template>
      </bsp-table-column>
      <bsp-table-column title="项目负责人" field="stManager" width="100" v-if="pageOprator == 'FZ-manage'"
                        filter-type="input" fixed="left">
        <template slot-scope="scope"><span>{{scope.row.wtManager}}</span></template>
      </bsp-table-column>

      <bsp-table-column title="申请时间" field="createTime" width="120" filter-type="input" fixed="left">
        <template slot-scope="scope"><span>{{scope.row.createTime}}</span></template>
      </bsp-table-column>

      <bsp-table-column title="操作" fixed="right" width="150px" align="center">
        <template slot-scope="scope">
          <div class="ciic-table-operate">
            <el-button type="text" @click="lookUpApply(scope.row)">查看详情</el-button>
          </div>
        </template>
      </bsp-table-column>
    </bsp-table>

    <!--详情-->
    <finTaxAgreementDetail v-if="this.detailVisible=='detail'"
                           refname="finTaxAgreementDetail"
                           :pageOprator="this.pageOprator"
                           :id="id"
                           :applicantName="applicantName"
                           :applyId="applyId"
                           @goback="goback"
                           v-loading="loading"
                           height="85%"
    >
    </finTaxAgreementDetail>

  </div>
</template>
<script>
  import BspTable from "@/components/bsp/bsp-table/bsp-table";
  import BspTableColumn from "@/components/bsp/bsp-table-column/bsp-table-column";
  import {getFinTaxAgreementList,exportDetails} from '@/api/agreement/agreement';
  import {downloadFile, downloadPdfFile, monthCalcu} from '@/util/util';
  import finTaxAgreementDetail from "@/views/agreement/fintax/finTaxAgreementDetail";
  import {mapGetters} from 'vuex';
  import {downLoadFinTaxAgreement, substituteConfirm} from '@/api/agreement/agreement';
  import auditBackPage from '@/views/agreement/fintax/auditBackPage'
  import BspDialog from "@/components/bsp/bsp-dialog/bsp-dialog";
  import {exportExcel} from "@/api/bankDirect/bankDirect";

  export default {
    name: 'finTaxApplyList',
    inject: ['reload'],
    components: {
      BspTable,
      BspTableColumn,
      finTaxAgreementDetail,
      auditBackPage,
      BspDialog
    },
    computed: {
      ...mapGetters([
        'dicts', 'userInfo'
      ]),
    },
    props: {
      currentTab: {
        type: String,
        default: ''
      },

    },
    watch: {
      '$route' (to, from) {
        // 在mounted函数执行的方法，放到该处
        this.__getFinTaxAgreementList();
      }
    },
    beforeRouteEnter(to, from, next) {
      next(vm => {
        vm.$router.replace(from.path)
        // 跳到该路由页面后，再替换为from.path来源路径
      })
    },
    created() {
      this.isDisabled = false;//初始化，按钮可用
      console.log("用户类型：" + this.userInfo.userType + "currentTab**********" + this.currentTab);
      debugger
      this.__getFinTaxAgreementList();
      if(this.userInfo.userType == 1){
        this.pageOprator = 'QG-manage';//全国
      } else {
        this.pageOprator = 'FZ-manage';//分支
      }
    },

    data() {
      return {
        pageOprator: 'QG-manage',
        id: '',
        // 遮罩
        loading: false,
        // 用于接收后台返回的分页数据
        list: [],
        detailVisible: 'false',
        //分页查询对象
        listQuery: {
          limit: 50,
          page: 1,
          total: 0,
          applyType: '',
          bizType: '',
          source: '0',//批次来源，区分是从补贴那里生成的还是自己导入生成的
          ids:[]
        },
        backTitle: '退回',
        backDialog: false,
        parameter: {
          batchIdList: [],
          batchImportIds: '',
          bizType: this.currentTab,
        },

        rules: {
          planPayTime: [
            {required: true, message: '请选择发薪时间', trigger: 'change'}
          ],
        },
        //多选数组
        multipleSelection: [],
        taxTotalStatus: 'dkTaxTotal',
        agreStatus: 'WSX',
        dataList: [],
        tableColumn: [], //显示表头
        sumItem: [],//需要合计的列
        detailImportCusList: [],
        showDetail: false,
        multipleDetailSelection: [],
        noChangeList: [],//明细中不编辑的列
        edit: true,
        isDisabled: true,
        isSubmitter: true,
        accountBalance: {
          account: '',
          accountName: '',
          balance: {
            type: Number,
            default: '0'
          }
        },
        isDisabledBatchStatus:false,
        listForm:{
          backReason:''
        },
      }
    },
    methods: {
      _back(){
        if (this.multipleSelection.length <= 0) {
          this.$message.error("请选择一条数据！");
          this.loading = false;
          return;
        }
        if (this.multipleSelection.length > 1) {
          this.$message.error("只能选择一条数据！");
          this.loading = false;
          return;
        }
        this.listForm.backReason = ''

        this.backDialog = true;
      },

      cancelLoadBtn(){
        console.log('取消加载样式');
        this.loading = false;
      },
      //选中的数据
      getSelectData(multipleDetailSelection) {
        console.log(multipleDetailSelection)
        this.multipleSelection = multipleDetailSelection;
      },

      selectChange(value) {
        console.log(value)
        if (value.selection) {
          this.multipleSelection = []
          for (let index = 0; index < value.selection.length; index++) {
            const element = value.selection[index]
            this.multipleSelection.push(element)
          }
          console.log('this.multipleSelection:' + this.multipleSelection)
        }
      },
      //复选框全选事件
      selectAll({checked, selection}) {
        if (checked) {
          this.multipleSelection = selection
        } else {
          this.multipleSelection = []
        }
        console.log('this.multipleSelection:' + this.multipleSelection)
      },

      //查询信息
      __getFinTaxAgreementList: function () {
        this.multipleSelection=[];
        console.log("查询批次信息");
        this.listQuery.applyType = this.currentTab;
        this.loading = true;
        getFinTaxAgreementList(this.listQuery).then(res => {
          if (res.data.code != 0) {
            this.listQuery.total = res.data.total
            this.list = res.data.records
            this.loading = false
          } else {
            this.loading = false
          }
        })
      },

      //返回
      goback() {
        debugger
        this.detailVisible='false';
        this.backDialog = false;
        this.listQuery.filters=[];
        this.__getFinTaxAgreementList();
      },

      //查看详情
      lookUpApply(row) {
        this.multipleSelection = [];
        this.multipleSelection.push(row);
        console.log(row.bizType + "===" + row.id)
        this.id = row.id;//协议ID
        this.applicantName = row.applicantName;//客户负责人
        this.applyId = row.applyId;//申请ID
        this.detailVisible = 'detail';
      },

      /*下载*/
      downLoadFinTaxAgreement(){
        if (this.multipleSelection.length <= 0) {
          this.$message.error("请选择一条数据！");
          this.loading = false;
          return;
        }
        if (this.multipleSelection.length > 1) {
          this.$message.error("只能选择一条数据！");
          this.loading = false;
          return;
        }
        debugger
        this.multipleSelection.forEach(row=>{
          downLoadFinTaxAgreement(row).then(res=>{
            debugger
            downloadPdfFile(res);
          })
        })
      },
      /*代确认*/
      async substituteConfirm() {
        this.loading = true;
        debugger
        if(this.agreStatus == 'SX'){//生效的协议不能再次代确认
          this.$message.error("生效的协议不能再次代确认");
          this.loading = false;
          return;
        }
        if (this.multipleSelection.length <= 0) {
          this.$message.error("请选择一条数据！");
          this.loading = false;
          return;
        }
        if (this.multipleSelection.length > 1) {
          this.$message.error("只能选择一条数据！");
          this.loading = false;
          this.loading = false;
          return;
        }
        //是否存在开通对公的机构，对公机构不允许代确认
        let orgNames = [];
        let flag = false;
        this.multipleSelection.forEach(row => {
          if(row.statusName != '待受托方确认') {
            flag = true;
          }
          if(row.openFlag == '00901' && orgNames.indexOf(row.orgName) < 0){
            orgNames.push(row.orgName);
          }
        })
        if(flag){
          this.loading = false;
          this.$message.error('状态不是“待受托方确认”的协议，不允许代确认；');
          return;
        }
        // if(orgNames.length > 0){
        //   let orgNameMsg = '';
        //   orgNames.forEach(orgName=>{
        //     orgNameMsg+='['+orgName+']';
        //   })
        //   this.loading = false;
        //   this.$message.error('以下机构开通了协作平台，不允许代确认；'+ orgNameMsg);
        //   return;
        // }

        substituteConfirm(this.multipleSelection).then(res=>{
          if(res.data.code == 0){
            this.$message.success('确认成功');
            this.loading = false;
            this.agreStatus = 'SX';
            this.__getFinTaxAgreementList();
          } else {
            this.loading = false;
            this.$message.error('确认失败，请联系运维人员');
          }
        })
      },

      // 导出excel
      exportDetails:function (){
        if(this.multipleSelection.length > 0){
          this.listQuery.ids=[];
          this.multipleSelection.forEach(row=>{
            this.listQuery.ids.push(row.id);
          })
        }
        exportDetails(this.listQuery).then(res=>{
          const url=window.URL.createObjectURL(res.data);
          const link=document.createElement('a');
          link.href=url;
          const fileName= `${res.headers['content-disposition'].replace('attachment;filename=','')}`;
          let channelUrl=decodeURIComponent(fileName);
          link.setAttribute('download',channelUrl);
          link.click()
        })
      },


  close(){
        debugger
  this.backDialog = false;
  },
      beforeClose (done) {
        this.backDialog = false;
      },
      async stGoBack(){

        if (this.multipleSelection.length <= 0) {
          this.$message.error("请选择一条数据！");
          this.loading = false;
          return;
        }
        if (this.multipleSelection.length > 1) {
          this.$message.error("只能选择一条数据！");
          this.loading = false;
          this.loading = false;
          return;
        }
        //是否存在开通对公的机构，对公机构不允许代确认
        let orgNames = [];
        let flag = false;
        this.multipleSelection.forEach(row => {
          if(row.statusName != '待受托方确认') {
            flag = true;
          }
          if(row.openFlag == '00901' && orgNames.indexOf(row.orgName) < 0){
            orgNames.push(row.orgName);
          }
        })
        if(flag){
          this.loading = false;
          this.$message.error('状态不是“待受托方确认”的协议，不允许退回；');
          return;
        }
        if(orgNames.length > 0){
          let orgNameMsg = '';
          orgNames.forEach(orgName=>{
            orgNameMsg+='['+orgName+']';
          })
          this.loading = false;
          this.$message.error('以下机构开通了协作平台，不允许退回；'+ orgNameMsg);
          return;
        }
        if(this.listForm.backReason == undefined || this.listForm.backReason == '' ||  this.listForm.backReason ==null ){
          this.$message.error('退回原因必填');
          return
        }
          this.$confirm(
            '退回后直接退回至协议创建人，是否确认?',
            '提示',
            {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }
          ).then(async () => {
            var form = this.multipleSelection[0];
           // await this.$refs.auditBackRef.agreementStBack(form);
            this.$refs.auditBackRef.auditBack(form, this.pageOprator,'退回成功！');
          }).catch(() => {
          })
          // if(this.pageOprator == 'QG-apply' || this.pageOprator == 'QG-manage'){
          //   this.form.applyId = this.applyId;
          //   this.form.applyType = this.applyType;
          //   this.$refs.auditBackRef.auditBack(this.form, this.pageOprator);
          // } else if(this.pageOprator == 'FZ-manage'){
          //   this.form.applyId = this.applyId;
          //   this.form.applyType = this.applyType;
          //   await this.$refs.auditBackRef.agreementStBack(this.form);
          // }

      },

    }
  }
</script>
<style scoped>
  .copy-btn {
    cursor: pointer;
  }

  .ciic-table-operate {
    align-items: center;
  }

  .editForm {
    margin: 0;
  }

  .editForm .el-form-item {
    margin: 0;
  }
</style>
<style>
  .winClass {
    width: 50% !important;
    height: 60% !important;
  }

  .el-message-box__content {
    overflow-y: auto !important;
    height: 80% !important;
  }
</style>
