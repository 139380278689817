import request from '@/router/axios';
const urlPrefix = ''

/**
 * 获取批次信息
 * @param obj
 */
export function getFinTaxAgreementApplyList(obj) {
  return request({
    url:urlPrefix+ '/finTaxAgreement/apply-list-page',
    method: 'post',
    data: obj
  })
}

export function getFinTaxPriceList(agreementId) {
  return request({
    url:urlPrefix+ '/finTaxAgreement/price-list',
    method: 'post',
    data: {agreementId}
  })
}

export function getFinTaxAgreementDetailInfo(agreementId) {
  return request({
    url:urlPrefix+ '/finTaxAgreement/getFinTaxAgreementDetailInfo',
    method: 'post',
    data: {agreementId}
  })
}

export function getFinTaxAgreementList(data) {
  return request({
    url:urlPrefix+ '/finTaxAgreement/agreement-list-page',
    method: 'post',
    data: data
  })
}

export function exportDetails(data) {
  return request({
    url:urlPrefix+ '/finTaxAgreement/exportAgreementList',
    responseType: 'blob',
    method: 'post',
    data: data
  })
}


export function auditBackApply(data) {
  return request({
    url:urlPrefix+ '/finTaxAgreement/auditBackApply',
    method: 'post',
    data: data
  })
}

export function auditPassApply(data) {
  return request({
    url:urlPrefix+ '/finTaxAgreement/auditPassApply',
    method: 'post',
    data: data
  })
}

export function agreementConfirm(data) {
  return request({
    url:urlPrefix+ '/finTaxAgreement/agreementConfirm',
    method: 'post',
    data: data
  })
}

export function agreementStBack(data) {
  return request({
    url:urlPrefix+ '/finTaxAgreement/agreementStBack',
    method: 'post',
    data: data
  })
}

export function downLoadFinTaxAgreement(data) {
  return request({
    url:urlPrefix+ '/finTaxAgreement/downLoadFinTaxAgreement',
    method: 'post',
    responseType: 'arraybuffer',
    data: data
  })
}

export function substituteConfirm(data) {
  return request({
    url:urlPrefix+ '/finTaxAgreement/substituteConfirm',
    method: 'post',
    data: data
  })
}

export function downLoadFinTaxAttach(id) {
  return request({
    url:urlPrefix+ '/finTaxAgreement/downLoadFinTaxAttach',
    responseType: 'blob',
    headers:{ 'Content-Type': 'application/json; application/octet-stream'},
    method: 'post',
    data: {id}
  })
}
