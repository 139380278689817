<template>
  <div >
  <el-tabs
    v-model="tabIndex"
    @tab-click="handleClick"
  >
    <el-tab-pane
      :key="item.name"
      v-for="(item,index) in tabList"
      :label="item.title"
      :name="item.name"
      lazy="true"
    >
      <slot />
      <div class="tabpaneCount" v-if="tabCount&&tabCount[index] > 0" slot="label">{{item.title}} <i>{{tabCount[index]}}</i></div>
    </el-tab-pane>
  </el-tabs>
  </div>
</template>

<script>
    export default {
      name: "bspTabs",
      props:{
        tabCount:{
          type: Array,
          default: () =>{
            []
          }
        },
          tabList:{
               type: Array,
               default: () =>{
                 []
               }
          },
          tabIndex:{
              type:String,
              default:''
          }
      },
      methods:{
          handleClick(tab, event){
            this.$emit('changeTab', tab,event)
        }
      }
    }
</script>
<style scoped>
  .tabpaneCount{
  }
  .tabpaneCount i{
    background-color: #F56C6C;
    border-radius: 10px;
    color: #FFF;
    display: inline-block;
    font-size: 13px;
    height: 15px;
    line-height: 15px;
    padding: 0 4px;
    text-align: center;
    white-space: nowrap;
    font-style: normal;
  }
</style>
