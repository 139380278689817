import request from '@/router/axios';
const urlPrefix = '';

// 查询下拉银行类型列表
export function searchBankTypeList() {
  return request({
    url: urlPrefix + '/searchBankTypeList',
    method: 'post'
  })
}

// 根据银行类型查询银行信息
export function queryBankInfoByType(obj) {
  return request({
    url: urlPrefix + '/searchBankListByType',
    method: 'post',
    data: obj
  })
}

// 根据条件查询交易明细列表
export function searchDetailListByConditions(obj) {
  return request({
    url: urlPrefix + '/searchDetailListByConditions',
    method: 'post',
    data: obj
  })
}

// 根据条件查询余额列表
export function searchOverageListByConditions(obj) {
  return request({
    url: urlPrefix + '/searchResidueListByConditions',
    method: 'post',
    data: obj
  })
}

// 明细信息在线下载
export function downloadOnline(obj) {
  return request({
    url: urlPrefix + '/downloadOnline',
    method: 'post',
    data: obj
  })
}

// 导出Excel
export function exportExcel(obj) {
  return request({
    url: urlPrefix + '/exportExcel',
    method: 'post',
    responseType: 'blob',
    data: obj
  })
}

// 余额在线下载
export function overageDownloadOnline(obj) {
  return request({
    url: urlPrefix + '/overageDownloadOnline',
    method: 'post',
    data: obj
  })
}


