<template>
  <div class="payment">

    <el-form  :inline="true" :model="listQuery" class="editForm ">

        <el-form-item label="支付单编号:">
          <el-input v-model="listQuery.paymentCode" placeholder="请输入内容" ></el-input>
        </el-form-item>
       <el-form-item label="状态:">
          <el-select  class="status-select" v-model="listQuery.paymentStatus" >
            <el-option label="全部" value=""></el-option>
            <el-option label="未转" value="1"></el-option>
            <el-option label="已转" value="2"></el-option>
            <el-option label="已发放" value="3"></el-option>

          </el-select>
        </el-form-item>
      <el-form-item label="核算月份:" >
          <el-date-picker
            style="width: 90%"
            v-model="listQuery.accountMonth"
            type="month"
            value-format="yyyy-MM"
            clearable
            placeholder="选择月份">
          </el-date-picker>
      </el-form-item>
      <el-form-item label="创建时间:" >
        <div class="block" >
          <el-date-picker
            style="width: 80%"
            v-model="startAndEndDate"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="起始日期"
            end-placeholder="截止日期"
            :picker-options="pickerOptions"
            value-format="yyyy-MM-dd HH:ss:mm"
            clearable
            @click="parseStartAndEndDate">
          </el-date-picker>
        </div>
      </el-form-item>
      <el-button type="primary" size="small" fixed="right" @click="searchPaymentsList">查询</el-button>
      <el-button type="primary" size="small" fixed="right" plain @click="payConfirm">确认</el-button>

      <!-- <el-form-item  >
        <template >
          <el-radio v-model="listQuery.paymentType" label="0"  style="margin-outside: 0px">全部</el-radio>
          <el-radio v-model="listQuery.paymentType" label="2"  style="margin-outside: 0px">薪资类</el-radio>
          <el-radio v-model="listQuery.paymentType" label="1"  style="margin-outside: 0px">非薪资类</el-radio>
        </template>
      </el-form-item> -->
    </el-form>
    <bsp-table
      :data.sync="list"
      :tree-config="true"
      :ciic-data-filter.sync="listQuery"
      :showPagination="false"
      @ciic-data-refresh="searchPaymentsList"
      show-header-overflow show-overflowwidth show-overflow
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      show-footer-overflow
      show-footer
      @select-change="selectChange"
      @select-all="selectAll"
      :scroll-x="{gt: 20}"
      :scroll-y="{gt: 20}"
      height="500"



    >

     <vxe-table-column type="selection" width="50" fixed="left"/>
     <vxe-table-column title="序号" type="index" width="60" fixed="left" align="center"></vxe-table-column>

      <bsp-table-column  title="操作" fixed="right" align="center" width="100">
        <template slot-scope="scope">
          <div class="ciic-table-operate">
            <el-button  type="text" @click="searchDetailInfo(scope.row)" >查看</el-button>
          </div>
        </template>
      </bsp-table-column>

     <bsp-table-column title="对公支付单编号" field="paymentCode" filter-type="input" fixed="left" width="100">
        <template slot-scope="scope"><span>{{scope.row.paymentCode}}</span></template>
      </bsp-table-column>

      <bsp-table-column title="状态" field="typeChangeOverall" filter-type="input" fixed="left"  width="100">
        <template slot-scope="scope"><span>{{scope.row.typeChangeOverall}}</span></template>
      </bsp-table-column>

      <bsp-table-column title="对私支付单编号" field="newname" filter-type="input" fixed="left" width="100">
        <template slot-scope="scope"><span>{{scope.row.newname}}</span></template>
      </bsp-table-column>

      <bsp-table-column title="薪资类别" field="payWageClass" filter-type="input" fixed="left" width="100">
        <template slot-scope="scope"><span>{{scope.row.payWageClass}}</span></template>
      </bsp-table-column>

      <bsp-table-column title="收款来源" field="gatheringSource" filter-type="input"  width="100" set-id="3052">
        <template slot-scope="scope"><span>{{scope.row.gatheringSource | dictFilter(dicts)}}</span></template>
      </bsp-table-column>

      <bsp-table-column title="创建方式" field="createType" filter-type="input"  width="100">
        <template slot-scope="scope"><span>{{scope.row.createType}}</span></template>
      </bsp-table-column>

      <bsp-table-column title="创建时间" field="createTime" filter-type="input"  width="100">
        <template slot-scope="scope"><span>{{scope.row.createTime}}</span></template>
      </bsp-table-column>

      <bsp-table-column title="核算月份" field="accountMonth" filter-type="input"  width="100">
        <template slot-scope="scope"><span>{{scope.row.accountMonth}}</span></template>
      </bsp-table-column>

      <bsp-table-column title="核算人" field="accounterId" filter-type="input"  width="100">
        <template slot-scope="scope"><span>{{scope.row.accounterId}}</span></template>
      </bsp-table-column>

      <bsp-table-column title="核算时间" field="accountTime" filter-type="input" width="100">
        <template slot-scope="scope"><span>{{scope.row.accountTime}}</span></template>
      </bsp-table-column>

      <bsp-table-column title="凭证号" field="voucherPk" filter-type="input" width="100">
        <template slot-scope="scope"><span>{{scope.row.voucherPk}}</span></template>
      </bsp-table-column>

      <bsp-table-column title="负责人" field="creatorId" filter-type="input" width="100">
        <template slot-scope="scope"><span>{{scope.row.creatorId}}</span></template>
      </bsp-table-column>

      <bsp-table-column title="回收发票" field="finishInvoiceFlag" filter-type="input" width="100">
        <template slot-scope="scope"><span>{{scope.row.finishInvoiceFlag}}</span></template>
      </bsp-table-column>

      <bsp-table-column title="备注" field="payRemark" filter-type="input" width="100">
        <template slot-scope="scope"><span>{{scope.row.payRemark}}</span></template>
      </bsp-table-column>
    </bsp-table>

    <div>



    </div>

  </div>
</template>
<script>

  import BspTable from "@/components/bsp/bsp-table/bsp-table";
  import BspTableColumn from "@/components/bsp/bsp-table-column/bsp-table-column";
  import {
    searchPaymentsList,
    searchDetailInfo,
    confirmRecord
  } from '@/api/payment/payment';
  import {downloadFile, downloadPdfFile} from '@/util/util';
  import {mapGetters} from 'vuex';
  import { Message } from 'element-ui'
  Vue.prototype.$message = Message

  export default {
    name: 'paymentDetail',
    inject: ['reload'],
    components: {
      BspTable,
      BspTableColumn,
    },
    computed: {
      ...mapGetters([
        'dicts'
      ]),
    },
    props: {
      currentTab: {
        type: String,
        default: ''
      },

    },
    created() {

      this.initDate();
      this.searchPaymentsList();

    },
    data() {
      return {
        // 下拉选中的银行类别
        bankTypes: [],
        // 根据银行类别选取的银行信息
        bankInfos: [],
        // 用于接收后台返回的分页数据
        list: [],
        //
        multipleSelection: [],
        //分页查询对象
        listQuery: {
          paymentCode: '',
          paymentStatus: '',
          accountMonth: '',
          paymentType: '0'
        },
        loading: false,
        returnCode:'',
        returnMessage:'',


        pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
        startAndEndDate: '',
      }
    },
    methods: {
      parseStartAndEndDate() {
        let date1 = this.startAndEndDate;
        this.listQuery.startDate = null;
        this.listQuery.endDate = null;
        if (date1 !== null && date1.length > 0) {
          if (date1[0] !== undefined) {
            this.listQuery.startDate = date1[0];
          }
          if (date1[1] !== undefined) {
            this.listQuery.endDate = date1[1];
          }
        }
      },
      // 初始化时间选择框
      initDate() {
        let now = new Date();
        let year = now.getFullYear(); //得到年份
        let month = now.getMonth(); //得到月份
        let date = now.getDate(); //得到日期
        month = month + 1;
        month = month.toString().padStart(2, "0");
        date = date.toString().padStart(2, "0");
        let defaultDate = `${year}-${month}-${date}`;
        this.startAndEndDate = [defaultDate + ' 00:00:00', defaultDate + ' 23:59:59'];
      },

      // 根据条件查询
      searchPaymentsList: function () {
        this.loading  =true;
        let llQuery = this.$route.query.listQuery;
        if (llQuery !== null && llQuery !== undefined){
          this.listQuery = llQuery;
          if (llQuery.startDate === null && llQuery.endDate === null) {
            this.startAndEndDate = null;
          } else {
            this.startAndEndDate = [this.listQuery.startDate, this.listQuery.endDate];
          }
        }
        this.$route.query.listQuery = null;

        this.parseStartAndEndDate();
        if (this.listQuery.startDate !== null && this.listQuery.endDate !== null && this.listQuery.startDate === this.listQuery.endDate){
          this.listQuery.endDate = `${this.listQuery.endDate.replace('00:00:00', '23:59:59')}`;
        }
        searchPaymentsList(this.listQuery).then(res => {
          this.list = res.data;
          this.loading  =false;
          this.multipleSelection = [];
        })
      },

      // 查看支付单明细
      searchDetailInfo: function (obj) {
        this.$router.push({path: '/consignee/payment_confirm_detail', query: {paymentId: obj.paymentId, payWageClass: obj.payWageClass, createType: obj.createType, listQuery: this.listQuery}})
      },

      //复选框全选事件
      selectAll({checked, selection}) {

        if(checked){
          this.multipleSelection= selection
        }else{
          this.multipleSelection=[];
        }
        console.log("this.multipleSelection:"+this.multipleSelection);
      },

      selectChange(value) {
        if (value.selection) {
          this.multipleSelection = [];
          for (let index = 0; index < value.selection.length; index++) {
            const element = value.selection[index];
            this.multipleSelection.push(element);
          }
        }
      },

      //支付确认
      payConfirm(){

        if(this.multipleSelection.length<=0){
              this.$message.error("请选择要确认的数据！");
              return;
        }
        if(this.multipleSelection.length>1){
              this.$message.error("只支持单一批次！");
              return;
        }
        let flag = false;
        for(let i=0;i<this.multipleSelection.length;i++){
          if(this.multipleSelection[i].typeChangeOverall=='已转'){
              flag = true;
              break
          }
        }
        if(flag){
          this.$message({
              type: 'info',
              message: "已转状态不可再次确认"
              });
              return false
        }
        this.multipleSelection.forEach(item=>{
                if(item.typeChangeOverall=='已转'){
                 this.$message({
                  type: 'info',
                  message: "已转状态不可再次确认"
                 });
                }else{
                   this.$confirm('请选择要确认的数据！', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'success'
                  }).then(() => {
                      confirmRecord(this.multipleSelection).then(res => {
                        this.returnCode = res.data.returnCode;
                        this.returnMessage = res.data.message;
                        if("200"!=this.returnCode){
                              this.$message({
                                type: 'info',
                                message: "确认失败"
                              });
                        }
                        if("200"==this.returnCode){
                        this.$message({
                          type: 'success',
                          message: "确认成功"
                        });
                        this.searchPaymentsList();
                        this.multipleSelection = [];
                      }
                    })
                    }).catch(() => {
                      this.$message({
                        type: 'info',
                        message: '已取消确认'
                      });
                    });
                }
              });
      }
    }
  }
</script>
<style scoped>

 .payment>>>.vxe-header--column.col--ellipsis>.vxe-cell>span:not(.vxe-cell--title){display:none}

  .copy-btn {
    cursor: pointer;
  }

  .ciic-table-operate {
    align-items: center;
  }

  .editForm {
    margin: 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

  }

  .editForm .el-form-item {
    margin: 0;
  }
  .editForm .el-form-item{
    /*width: 30%;*/
    padding: 10px 4px;
    display: flex;
  }

  .editForm .el-form-item:first-child,.editForm .el-form-item:nth-child(3){
    flex:1;
  }

  .editForm .el-form-item >>> .el-form-item__label{
    flex-shrink: 0;
  }
  @media (min-width:1367px){
    .editForm .el-form-item:nth-child(2){
      flex:1;
    }
    .editForm .el-form-item:nth-child(4){
      flex:2;
    }
  }
  @media (max-width:1367px){
    .editForm .el-form-item:nth-child(2){
      width: 130px;
    }
    .editForm .el-form-item:nth-child(4){
      width: 290px;
    }
    .editForm .el-form-item >>> .el-form-item__label{
      padding-right: 8px;
    }
    .editForm .el-form-item  >>> .el-radio__label{
      padding-left: 4px;
    }
    .editForm .el-form-item  >>> .el-radio{
      margin-right: 4px;
    }
  }

</style>
