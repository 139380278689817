<template>
  <div>
    <el-form v-loading="loading" ref="listForm" class="editFrom" :model="listForm" label-width="100px" :rules="rules">
      <el-row>
        <el-col :span="24">
          <el-form-item label="原因：" prop="backReason">
            <el-input v-model="listForm.backReason" type="textarea"/>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
  import {downloadFile} from '@/util/util';
  import {mapGetters} from 'vuex';
  import {auditBackApply, agreementStBack} from '@/api/agreement/agreement';

  export default {
    name: "auditBackPage",
    inject: ['reload'],
    props:{
      listForm:{
        type: Object,
        default () {
          return {
          }
        }
      },
      passFlag: false,
    },
    computed: {
      ...mapGetters([
        'dicts', "userInfo"
      ]),
    },
    created() {
      console.log(this.listForm);
    },

    data() {
      return {
        operType: '',
          // 遮罩
        loading: false,
        rules: {
          backReason: [
            {required: true, message: '请选择填写退回原因', trigger: 'blur'}
          ],
        },
      }
    },

    methods: {
      //审核退回
      async auditBack(obj, pageOprator,message){
        if(this.listForm.backReason.length > 200){
          this.$message.error('驳回原因不能超过200字');
          return
        }
        this.loading = true;
        this.$refs["listForm"].validate( async (valid) => {
          if(valid){
            obj.reason = this.listForm.backReason;
            console.log(this.listForm.backReason)
            await auditBackApply(obj).then(res => {
              if(res.data.code == 0){
                if(message){
                  this.$message.success(message);
                }else{
                  this.$message.success('协议审核驳回成功');
                }
                this.$emit('back');
              } else {
                this.$message.error('协议审核驳回失败，请联系运维');
              }
            })
            this.loading = false;
          } else {
            this.loading = false;
          }
        });
      },

      //受托退回
      async agreementStBack(obj){
        if(this.listForm.backReason.length > 200){
          this.$message.error('驳回原因不能超过200字');
          return
        }
        this.loading = true;
        this.$refs["listForm"].validate( async (valid) => {
          if(valid){

            obj.reason = this.listForm.backReason;
            console.log(this.listForm.backReason)
            agreementStBack(obj).then(res => {
              if(res.data.code == 0){
                this.$message.success('协议退回成功');
                this.$emit('back');
              } else {
                this.$message.error('协议退回失败，请联系运维');
              }
            })
            this.loading = false;
          } else {
            this.loading = false;
          }
        });
      },
    }
  }
</script>

<style scoped>

</style>
