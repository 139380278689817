<template>
  <div>
  <el-dialog v-dialogDrag
             :custom-class="className"
             @opened ="opened"
             :ref="dialogRef"
             :width="width"
             :height="height"
             v-bind = "{...$attrs}"
             v-on="{...$listeners}"
             :close-on-click-modal="false"
             :close-on-press-escape="false"
             :title="title"
             :visible.sync="dialogVisible"
             :before-close="handleClose"
  >
    <slot name="body"></slot>
    <slot slot="footer" name="footer"></slot>
  </el-dialog>
  </div>
</template>

<script>
  export default {
    name: "bsp-dialog",
    props: {
      //title
      title: {
        type:String,
        default:''
      },
      //title
      className: {
        type:String,
        default:''
      },
      //ref
      dialogRef: {
        type:String,
        default:''
      },
      // width
      width: {
        type: String,
        default: ''
      },
      // width
      height: {
        type: String,
        default: ''
      },
      // visible
      dialogVisible: {
        type: Boolean,
        default: false
      },

    },
    methods: {
      handleClose(done) {
        this.$emit("close")
      },
      opened(){
        this.$emit("opened")
      }
    }
  };
</script>

<style scoped>

</style>
