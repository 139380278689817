<template>
  <div>
    <el-form :inline="true" :model="listQuery" class="editForm" label-width="50px" v-if="this.detailVisible=='false'" size="small">
      <el-form-item label="状态:">
        <el-select clearable class="status-select" v-model="listQuery.applyStatus"
                   @change="__getFinTaxAgreementApplyList()" :disabled = "this.isDisabledBatchStatus">
          <el-option label="未受理" value="00900"></el-option>
          <el-option label="已受理" value="00901"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div class="divButtonRigth">
      <el-button type="primary" size="small" fixed="right"
                 v-if="detailVisible=='detail'"
                 :disabled="isDisabled" @click="goback">返回</el-button>
    </div>
    <!--申请列表-->
    <bsp-table v-if="this.detailVisible=='false'"
               :data.sync="list"
               :tree-config="true"
               :ciic-data-filter.sync="listQuery"
               @ciic-data-refresh="__getFinTaxAgreementApplyList"
               @select-change="selectChange"
               @select-all="selectAll"
               show-header-overflow show-overflowwidth show-overflow
               v-loading="loading"
               element-loading-text="拼命加载中"
               element-loading-spinner="el-icon-loading"
               show-footer-overflow
               show-footer
               :footer-method="footerMethod"
               height="89%"
    >
      <vxe-table-column type="selection" width="60" fixed="left"/>
      <vxe-table-column title="序号" type="index" width="60" fixed="left" align="center"></vxe-table-column>
      <bsp-table-column title="受托方" field="orgName" filter-type="input" fixed="left">
        <template slot-scope="scope"><span>{{scope.row.orgName}}</span></template>
      </bsp-table-column>
      <bsp-table-column title="客户名称" field="cusName" filter-type="input" fixed="left">
        <template slot-scope="scope"><span>{{scope.row.cusName}}</span></template>
      </bsp-table-column>

      <bsp-table-column title="地区" field="region" filter-type="input" fixed="left">
        <template slot-scope="scope"><span>{{scope.row.region}}</span></template>
      </bsp-table-column>

      <bsp-table-column title="业务类型" field="biz_type" fixed="left"
                        filter-type="select"    filter-rule="in"   set-id="3371">
        <template slot-scope="scope"><span>{{scope.row.bizTypeName | dictFilter(dicts) }}</span></template>
      </bsp-table-column>

      <bsp-table-column title="服务业务" field="serviceBusiness" fixed="left"
                        filter-type="select-like"    filter-rule="input"   set-id="3372">
        <template slot-scope="scope"><span>{{scope.row.serviceBusinessName | dictFilter(dicts) }}</span></template>
      </bsp-table-column>

      <bsp-table-column title="服务项目" field="serviceItem" filter-type="input" fixed="left">
        <template slot-scope="scope"><span>{{scope.row.serviceItem}}</span></template>
      </bsp-table-column>

      <bsp-table-column title="协议状态" field="agreement_status" fixed="left"
                        filter-type="select"    filter-rule="in"   set-id="3374">
        <template slot-scope="scope"><span>{{scope.row.statusName | dictFilter(dicts) }}</span></template>
      </bsp-table-column>
      <bsp-table-column title="受托负责人" field="stManager" width="100"
                        filter-type="input" fixed="left">
        <template slot-scope="scope"><span>{{scope.row.stManager}}</span></template>
      </bsp-table-column>
      <bsp-table-column title="客户负责人" field="applicantName" width="100"
                        filter-type="input" fixed="left">
        <template slot-scope="scope"><span>{{scope.row.applicantName}}</span></template>
      </bsp-table-column>

      <bsp-table-column title="申请时间" field="createTime" width="120" filter-type="input" fixed="left">
        <template slot-scope="scope"><span>{{scope.row.createTime}}</span></template>
      </bsp-table-column>

      <bsp-table-column title="操作" fixed="right" width="150px" align="center">
        <template slot-scope="scope">
          <div class="ciic-table-operate">
            <el-button type="text" @click="lookUpApply(scope.row)">查看申请</el-button>
          </div>
        </template>
      </bsp-table-column>
    </bsp-table>

    <!--协议详情页面-->
    <finTaxAgreementDetail v-if="this.detailVisible=='detail'"
                         refname="finTaxAgreementDetail"
                         :pageOprator="this.pageOprator"
                         :id="id"
                         :applyStatus="applyStatus"
                         :applicantName="applicantName"
                         :applyId="applyId"
                         :applyType="applyType"
                         @goback="goback"
                         v-loading="loading"
                         height="85%"
    >
    </finTaxAgreementDetail>

  </div>
</template>
<script>
  import BspDialog from "@/components/bsp/bsp-dialog/bsp-dialog";
  import BspTable from "@/components/bsp/bsp-table/bsp-table";
  import BspTableColumn, {detailImportList} from "@/components/bsp/bsp-table-column/bsp-table-column";
  import {getFinTaxAgreementApplyList} from '@/api/agreement/agreement';
  import {downloadFile, downloadPdfFile, monthCalcu} from '@/util/util';
  import finTaxAgreementDetail from "@/views/agreement/fintax/finTaxAgreementDetail";
  import {mapGetters} from 'vuex';

  export default {
    name: 'finTaxApplyList',
    inject: ['reload'],
    components: {
      BspTable,
      BspTableColumn,
      BspDialog,
      finTaxAgreementDetail
    },
    watch: {
      '$route' (to, from) {
        // 在mounted函数执行的方法，放到该处
        this.__getFinTaxAgreementApplyList();
      }
    },

    beforeRouteEnter(to, from, next) {
      next(vm => {
        vm.$router.replace(from.path)
        // 跳到该路由页面后，再替换为from.path来源路径
      })
    },
    computed: {
      ...mapGetters([
        'dicts', 'userInfo'
      ]),
    },
    props: {
      currentTab: {
        type: String,
        default: ''
      },

    },
    created() {
      this.isDisabled = false;//初始化，按钮可用
      console.log("用户类型：" + this.userInfo.userType + "currentTab**********" + this.currentTab);
      this.__getFinTaxAgreementApplyList();
    },

    watch: {
      '$route' (to, from) {
        this.$router.go(0);
      }
    },

    data() {
      return {
        pageOprator: 'QG-apply',
        id: '',
        applyId: '',
        applyStatus: '00900',//用于控制明细页面的按钮
        applicantName: '',
        // 遮罩
        loading: false,
        // 用于接收后台返回的分页数据
        list: [],
        detailVisible: 'false',
        //分页查询对象
        listQuery: {
          limit: 50,
          page: 1,
          total: 0,
          applyType: '',
          bizType: '',
          applyStatus: '00900',//默认为未受理
          source: '0',//批次来源，区分是从补贴那里生成的还是自己导入生成的
        },

        parameter: {
          batchIdList: [],
          batchImportIds: '',
          bizType: this.currentTab,
        },

        rules: {
          planPayTime: [
            {required: true, message: '请选择发薪时间', trigger: 'change'}
          ],
        },
        //多选数组
        multipleSelection: [],
        taxTotalStatus: 'dkTaxTotal',
        dataList: [],
        tableColumn: [], //显示表头
        sumItem: [],//需要合计的列
        detailImportCusList: [],
        showDetail: false,
        multipleDetailSelection: [],
        noChangeList: [],//明细中不编辑的列
        edit: true,
        isDisabled: false,
        isSubmitter: true,
        accountBalance: {
          account: '',
          accountName: '',
          balance: {
            type: Number,
            default: '0'
          }
        },
        isDisabledBatchStatus:false
      }
    },
    methods: {

      //查询申请批次信息
      __getFinTaxAgreementApplyList: function () {
        this.multipleSelection=[];
        if (this.listQuery.applyStatus.length <= 0) {
          this.$message.error("请至少选择一种状态！");
          this.list = null;
          return;
        }
        console.log("查询批次信息");
        this.listQuery.applyType = this.currentTab;
        this.loading = true;
        debugger
        getFinTaxAgreementApplyList(this.listQuery).then(res => {
          if (res.data.code != 0) {
            this.listQuery.total = res.data.total
            this.list = res.data.records
            this.loading = false
          } else {
            this.loading = false
          }
        })
      },

      //返回
      goback() {
        debugger
        this.detailVisible='false';
        this.listQuery.filters=[];
        this.__getFinTaxAgreementApplyList();
      },

      //查看详情
      lookUpApply(row) {
        console.log(row.bizType + "===" + row.id)
        this.id = row.id;
        this.applyStatus = this.listQuery.applyStatus;
        this.applicantName = row.applicantName;
        this.applyId = row.applyId;
        this.applyType = this.currentTab;//申请类型
        this.$store.state.tags.currentTab = this.currentTab;
        this.detailVisible = 'detail';
      },
    }
  }
</script>
<style scoped>
  .copy-btn {
    cursor: pointer;
  }

  .ciic-table-operate {
    align-items: center;
  }

  .editForm {
    margin: 0;
  }

  .editForm .el-form-item {
    margin: 0;
  }
</style>
<style>
  .winClass {
    width: 50% !important;
    height: 60% !important;
  }

  .el-message-box__content {
    overflow-y: auto !important;
    height: 80% !important;
  }
</style>
