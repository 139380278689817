<template>
  <div class="iframIndex" id="iframIndex">
<!--    {{preUrl}}{{routerPath}}-->
    <!--<iframe-->
      <!--id="main"-->
      <!--:ref="preUrl"-->
      <!--:src="getRouter(preUrl,routerPath,routerParms,parmStr)"-->
      <!--frameborder="0"-->
      <!--width="100%"-->
      <!--height="100%"-->
    <!--&gt;</iframe>-->
  </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import {encrypt,addClass,removeClass} from '@/util/util'
    import {base64Encode} from '@/util/endecode.js'
    export default {
        name: "ifram",
        computed: {
            ...mapGetters([])
        },
        data(){
            return {
                preUrl: process.env.VUE_PUB_BASE_URL ,
                routerPath: this.$route.path ,
                routerParms: this.$route.query,
                parmStr: '',
								themeType:'',
            };
        },
        watch: {
            $route: {
                handler: function(val, oldVal){
                    this.routerPath = val.path;

                    this.routerParms = val.query;
                },
                // 深度观察监听
                deep: true
            },
          routerPath: {
            handler(newVal, oldVal) {
              this.$nextTick(function() {
                // 为了解决浏览器回退的路由问题
                // 如果当前存在iframe,销毁它
                var div = document.getElementById("iframIndex");
                if(div.firstChild)div.removeChild(div.firstChild);
                // 创建iframe
                var iframe = document.createElement('iframe');
                iframe.id = "main";
                iframe.ref = this.preUrl;
                iframe.src = this.getRouter(this.preUrl, this.routerPath, this.routerParms, this.parmStr);
                iframe.frameborder = "0";
                iframe.width = "100%";
                iframe.height = "100%";
                document.getElementById('iframIndex').appendChild(iframe);
              })
            },
            immediate: true,
            deep: true,
          }
        },
        created() {
          console.log("routerPath ;",this.routerPath);
          console.log("VUE_APP_BASE_URL ;",process.env.VUE_APP_BASE_URL);
         // this.preUrl ='http://localhost:8080/WebRoot';//process.env.VUE_PUB_BASE_URL;
          if(window.document.location.href.indexOf("10.0.128.150")>0){
            this.preUrl = "http://10.0.128.150:9000";
          }else if(window.document.location.href.indexOf("ciicpt.com")>0){
            this.preUrl =process.env.VUE_APP_PUB_BASE_PT_URL;
          }else {
            this.preUrl =process.env.VUE_APP_PUB_BASE_PT_URL;
          }
          this.themeType = this.$store.getters.themeType;
          var lang = localStorage.getItem('lang');
          if(lang) {
            lang = lang;
          }else{
            lang = "zh";
          }
          console.log("token",this.$store.getters.accessToken);
          if(!this.themeType){
            this.themeType ='';
          }

          this.parmStr=  'lang='+base64Encode(lang)+'&accessToken='+base64Encode(encrypt(this.$store.getters.accessToken,''))+'&themeType='+base64Encode(this.themeType)+'&SysVersion='+base64Encode('NEW');
        },
        filters:{

        },
        mounted(){
					this.$nextTick(function(){
						addClass(document.getElementsByTagName('body')[0],'pubIndex');
					})
        },
        destroyed(){

          removeClass(document.getElementsByTagName('body')[0],'pubIndex');
        },
        methods: {
          createdIframe(){

          },
            getRouter:function(preUrl,routePath,routeParms,parmStr){
              console.log("地址：：",preUrl,'  route:',routePath,'  路由参数：',routeParms,'  参数：',parmStr)
                addClass(document.getElementsByTagName('body')[0],'pubIndex');
                if(routeParms){
                    let routeParmStr = "";
                    for(let key in routeParms){
                        routeParmStr += "&"+key+"="+routeParms[key]
                    }
                    console.log(preUrl + routePath +"?"+ parmStr + routeParmStr)
                    return preUrl + routePath +"?"+ parmStr + routeParmStr;
                }else{
                    return preUrl + routePath +"?"+ parmStr;
                }
                return "";
            }
        }
    }
</script>

<style scoped>
    .iframIndex{
        height: 100%;
        box-sizing: border-box;
    }
</style>
