import { render, staticRenderFns } from "./paymentConfirm.vue?vue&type=template&id=ef7f979e&scoped=true&"
import script from "./paymentConfirm.vue?vue&type=script&lang=js&"
export * from "./paymentConfirm.vue?vue&type=script&lang=js&"
import style0 from "./paymentConfirm.vue?vue&type=style&index=0&id=ef7f979e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ef7f979e",
  null
  
)

export default component.exports