<template>
  <div>
    <div>带过滤的表格</div>
    <bsp-table  :data.sync="list" ref="accountTable"
               @select-change="selectChange"
               :ciic-data-filter.sync="listQuery"
               @select-all="selectAll"
               @ciic-data-refresh="getList">
      <vxe-table-column type="selection" width="60" fixed="left" align="center" ></vxe-table-column>
      <vxe-table-column title="序号" type="index" width="60" fixed="left" align="center"></vxe-table-column>
      <bsp-table-column title="操作" fixed="left" align="center" >
        <template slot-scope="scope">
          <template v-if="$refs.accountTable.hasActiveRow(scope.row)">
            <el-button size="small" @click="testhandle(scope.row)">保存</el-button>
            <el-button size="small" @click="cancelRowEvent(scope.row)">取消</el-button>
          </template>
          <template v-else>
            <div class="ciic-table-operate">
              <el-button size="small" @click="showDialog(scope.row)">编辑</el-button>
              <el-button size="small" type="success">删除</el-button>
            </div>
          </template>
        </template>
      </bsp-table-column>
      <bsp-table-column field="name"  title="姓名" :slot-filter="true" :filters="[{data: ''}]" align="center" >
        <template slot-scope="scope">
          <span>{{ scope.row.name}}</span>
        </template>
        <template slot="filter" slot-scope="scope">
          <template v-for="(option, index) in scope.row.column.filters">
            <el-input type="type" v-model="option.data" :key="index"
                      @input="scope.row.context.changeOption($event, !!option.data, option)"></el-input>
          </template>
        </template>
      </bsp-table-column>
      <bsp-table-column filter-rule="in" edit-type="select" filter-type="select" field="gender" title="性别" align="center"  set-id="0100" >
        <template slot-scope="scope">
          <span>{{ scope.row.gender | dictFilter(dicts)}}</span>
        </template>
      </bsp-table-column>
      <bsp-table-column v-if="idTypeList.length > 0" filter-rule="in" :optionProps="{value:'codeItemId',label:'codeItemName'}" edit-type="select"
                        filter-type="select" field="idType" title="证件类型" :optionsList="idTypeList">
        <template slot-scope="scope">
          <span>{{ scope.row.idType| dictFilter(dicts)}}</span>
        </template>
      </bsp-table-column>
      <bsp-table-column field="idCard" show-overflow="title" :edit-render="{name: 'input'}" title="证件号码">
        <template slot-scope="scope">
          <span>{{ scope.row.idCard}}</span>
        </template>
      </bsp-table-column>
      <bsp-table-column field="birthday" filter-rule="between" align="center"
                        sortable title="出生日期" filter-type="datePicker"
                        edit-type="datePicker"
                        :edit-render="{name: 'ElDatePicker'}">
        <template slot-scope="scope">
          <span>{{ scope.row.birthday }}</span>
        </template>
      </bsp-table-column>
      <bsp-table-column field="city" filter-rule="in" title="市区" align="center"  filter-type="select">
        <template slot-scope="scope">
          <span>{{ scope.row.city | dictFilter(serverDictsMap)}}</span>
        </template>
      </bsp-table-column>

      <bsp-table-column field="achievement" sortable title="成绩" filter-type="input" align="center"  :edit-render="{name: 'input'}">
        <template slot-scope="scope">
          <span>{{ scope.row.achievement }}</span>
        </template>
      </bsp-table-column>

    </bsp-table>
  </div>
</template>

<script>
  import bspTable from "@/components/bsp/bsp-table/bsp-table";
  import bspTableColumn from "@/components/bsp/bsp-table-column/bsp-table-column";
  import {getServerDictsMap, getServerDictsList, getDictsListBySetId} from '@/api/sys/code'
  import {getListpage,updateListpage} from '@/api/demo/demo'
  import {mapGetters} from 'vuex';

  export default {
    name: "table",
    components: {bspTableColumn, bspTable},
    created() {
      this.getServerDictsMap();
      this.getIdTypeList();
      this.getList();
    },
    mounted() {
    },
    computed: {
      ...mapGetters([
        'dicts',
      ]),
    },
    data() {
      return {
        serverDictsMap: null,
        dialogFormVisible: false,
        serverDictsList: null,
        idTypeList: [],
        list: [
        ],
        sexList: [
          {
            id: '1',
            name: '已提交'
          },
          {
            id: '2',
            name: '审核中'
          },
          {
            id: '3',
            name: '已完成'
          }
        ],
        listQuery: {
          limit: 50,
          page: 1,
          total: 0,
        },
        currentValue: '02',
        selectData: [
        ],

      }
    },
    methods: {
        footerMethod({columns, data}) {
          return [
            columns.map((column, columnIndex) => {
              if (columnIndex === 0) {
                return '平均'
              }
              if (['age'].includes(column.property)) {
                console.log(data, column.property)
                return 20
              }
              return null
            }),
            columns.map((column, columnIndex) => {
              if (columnIndex === 0) {
                return '和值'
              }
              if (['age'].includes(column.property)) {
                return 80
              }
              return null
            })
          ]
        },
        showDialog(row) {
          console.log('ceshi')
        },
      // 表格内修改保存数据
        testhandle(row) {
          //在这里做一些后台数据处理
          console.log(row)
          updateListpage(row).then(res=>{
            this.$message.success("修改成功")
            this.getList();
            console.log(res);
          });
        },
        getServerDictsMap() {
          getServerDictsMap().then(res => {
            console.log(res)
            this.serverDictsMap = res.data.data
          })
        },
        getServerDictsList() {
          getServerDictsList().then(res => {
            this.serverDictsList = res.data.data;
          })
        },
        getIdTypeList() {
          getDictsListBySetId('0034').then(res => {
            this.idTypeList = res.data;
          })
        },

        getList() {
          getListpage(this.listQuery).then(res => {
            this.listQuery.total = res.data.total
            this.list = res.data.records
          })
        },
        //复选框单选事件
        selectChange({checked, row}) {
          console.log("dand xuan")
          console.log(checked, row)
        },
        //复选框全选事件
        selectAll({checked, selection}) {
          console.log("quan xuan")
          console.log(checked, selection)
        },
      }
  }
</script>

<style scoped>

</style>
